import * as React from "react"
import Seo from "../components/seo"
import RewardLinkComponent from "../components/RewardLinkComponent"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "./showcase.css"
const CardIndex = () => {
  const [rewardToken, setRewardToken] = React.useState(null)

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get("referral")
    if (token) {
      setRewardToken(token)
    }
  }, [])

  return (
    <div>
      <div
        className="parallax"
        style={{
          backgroundImage: `linear-gradient(${"#f0d723"} 1vh,${"orange"})`,
        }}
      />
      <header className="showcase-header">
        <Seo
          title={"Learn German Articles"}
          lang={"en"}
          canonical={"showcase"}
          description={
            "How to learn German articles? Each noun in German has a corresponding gender and an associated article that you should learn in conjunction with another. Der Die Das Train is a game of German nouns and their articles (der, die, and das) and will help you learn and memorize which article goes with which noun."
          }
        />
        <div className="container">
          <div className="main_nav">
            <a
              className="showcase-a mobile-toggle"
              href="https://der-die-das-train.com"
            >
              <span /> <span /> <span />
            </a>
            <nav className="showcase-nav">
              <ul>
                <li>
                  <a className="smoothscroll showcase-a" href="/">
                    Home
                  </a>
                </li>
                <li>
                  <a className="smoothscroll showcase-a" href="#about">
                    About
                  </a>
                </li>
                <li>
                  <a className="smoothscroll showcase-a" href="#nouns">
                    Nouns
                  </a>
                </li>
                <li>
                  <a className="smoothscroll showcase-a" href="#gameplay">
                    Gameplay
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div id="first-container" className="row showcase-hero">
            <div className="eight columns">
              <div className="app-icon-outer">
                <div class="image-container">
                  <StaticImage
                    className="bio-avatar"
                    id="wrap-image"
                    layout="fixed"
                    formats={["auto", "webp", "avif"]}
                    src="../images/icon.png"
                    width={100}
                    height={100}
                    quality={95}
                    alt="Application icon"
                  />
                </div>
              </div>
              <h1 className="header">Der Die Das Train</h1>
              <h3 className="subheader">A Game of German Articles</h3>

              {rewardToken ? (
                <RewardLinkComponent
                  rewardToken={rewardToken}
                ></RewardLinkComponent>
              ) : (
                <p className="paragraph">
                  Master German articles with our thrilling game! Impress your
                  friends as you climb the leaderboards and become a true native
                  speaker!
                </p>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "0rem",
                }}
              >
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.der.die.das.train"
                >
                  <StaticImage
                    src="../images/google-play-badge.png"
                    alt="Download on Google Play"
                    style={{ color: "#f67a40" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={60}
                  />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://apps.apple.com/us/app/der-die-das-train-lite/id1562981120?platform=iphone"
                >
                  <StaticImage
                    src="../images/apple-store-badge.png"
                    alt="Download on Apple Store"
                    style={{ color: "#f67a40" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={60}
                  />
                </a>
              </div>
            </div>
            <div className="four columns">
              <StaticImage
                src="../images/mirror/1.png"
                className="phone device showcase-img"
                alt="phone mockup"
                placeholder={"blurred"}
                fit={"contain"}
                height={600}
              />
            </div>
          </div>
        </div>
      </header>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "4rem",
        }}
      >
        <p style={{ margin: "1.5rem", marginTop: "2rem", color: "#525252" }}>
          Download our app now! It's available on both the App Store for iOS and
          Google Play for Android.
        </p>
      </div>
      <section className="show-section" id="about">
        <div className="container">
          <div className="eight columns">
            <div className="row">
              <h2>A Game of German Articles</h2>
            </div>
            <div className="row">
              <div className="six columns">
                <p>
                  Der Die Das Train is an engaging game that helps you master
                  German nouns and their corresponding articles - ‘der’, ‘die’,
                  and ‘das’. Learn and memorize with ease as you play!
                </p>
                <p>
                  Learn new German words and their articles in a fun and
                  engaging way with our train-themed game.
                </p>
              </div>
              <div className="six columns">
                <p>
                  Say goodbye to the frustration of remembering ‘der’, ‘die’,
                  and ‘das’ - the foundation of German grammar a major challenge
                  for any German learner.
                </p>
              </div>
            </div>
          </div>
          <div className="four columns">
            <StaticImage
              src="../images/mirror/en/2.png"
              className="showcase-img"
              alt="phone mockup"
              placeholder={"blurred"}
              fit={"contain"}
              height={750}
            />
          </div>
        </div>
      </section>
      <section id="nouns" className="show-section">
        <div className="container">
          <div className="row" id="section-heading">
            <h2>German Nouns and Their Articles</h2>
          </div>
          <div className="row">
            <div className="four columns">
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-location-outline showcase-icon" />
                </div>
                <div className="nine columns">
                  <h2>Noun + Article</h2>
                  <p>
                    In German, every noun has a gender and a corresponding
                    article. It’s important to learn these together.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-lightbulb showcase-icon" />
                </div>
                <div className="nine columns">
                  <h2>Rules?</h2>
                  <p>
                    There are general rules or grouping of nouns, either by
                    subject area or by which letters they end on. As always,
                    there are exceptions to these rules. These rules and, when
                    applicable, exceptions are integrated into the game at
                    certain points.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-spanner-outline showcase-icon" />
                </div>
              </div>
            </div>
            <div className="four columns">
              <StaticImage
                src="../images/mirror/1.png"
                className="phoneFeature showcase-img"
                alt="phone mockup"
                placeholder={"blurred"}
                fit={"contain"}
                height={750}
              />
            </div>
            <div className="four columns">
              <div className="row">
                <div className="nine columns">
                  <h2>Gender + Grammar</h2>
                  <p>
                    “Mastering German grammar is easy with our game! Knowing the
                    gender and article of a noun is key to unlocking the
                    language. Our game makes learning this foundation fun and
                    engaging.
                  </p>
                </div>
                <div className="three columns">
                  <span className="typcn typcn-key-outline showcase-icon" />
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-battery-high showcase-icon" />
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-flash-outline showcase-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="gameplay" className="show-section">
        <div className="container">
          <div className="eight columns">
            <div className="row">
              <h2>Gameplay</h2>
            </div>
            <div className="row">
              <div className="six columns">
                <p>
                  Discover new words and expand your vocabulary as you navigate
                  the subway network! Our system groups words thematically for
                  easy memorization and suggests topics based on conceptual
                  difficulty, recently visited categories, and memory retention
                  over time. Hop on board and start exploring today!
                </p>
                <p>
                  Take control of the train with a simple touch of the screen
                  and guide it towards the corresponding article for each noun
                  that appears at the top of your screen. With in-game
                  translations (currently only available in English), learning
                  and expanding your vocabulary has never been easier or more
                  fun!
                </p>
              </div>
              <div className="six columns">
                <p>
                  Reinforce your learning with each word spoken out loud and
                  accompanied by sound effects. Easily access words, articles,
                  tips for remembering articles, translations and definitions
                  all from within the game menu. Learning has never been more
                  interactive and engaging!
                </p>
                <p>
                  Play for hours on end without getting bored and showcase your
                  German language skills on the leaderboards. Challenge yourself
                  and see how far you can go!
                </p>
              </div>
            </div>
          </div>
          <div className="four columns">
            <StaticImage
              src="../images/mirror/en/3.png"
              className="showcase-img"
              alt="phone mockup"
              placeholder={"blurred"}
              fit={"contain"}
              height={750}
            />
          </div>
        </div>
      </section>
      <section id="leaderboard" className="show-section">
        <div className="container">
          <div className="row" id="section-heading">
            <h2>Leaderboards</h2>
          </div>
          <div className="row">
            <div className="four columns">
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-location-outline showcase-icon" />
                </div>
                <div className="nine columns">
                  <h2>Stay Motivated</h2>
                  <p>
                    Rack up points and soar to the top of the leaderboards! Stay
                    motivated and driven as you compete to be the best!
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-lightbulb showcase-icon" />
                </div>
                <div className="nine columns">
                  <h2></h2>
                  <p></p>
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-spanner-outline showcase-icon" />
                </div>
              </div>
            </div>
            <div className="four columns">
              <StaticImage
                src="../images/mirror/en/4.png"
                className="phoneFeature showcase-img"
                alt="phone mockup"
                placeholder={"blurred"}
                fit={"contain"}
                height={750}
              />
            </div>
            <div className="four columns">
              <div className="row">
                <div className="nine columns">
                  <h2>Streak</h2>
                  <p>
                    Keep your streak alive by practicing every day! Consistency
                    is key to success and we’re here to help you achieve it!
                  </p>
                </div>
                <div className="three columns">
                  <span className="typcn typcn-key-outline showcase-icon" />
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-battery-high showcase-icon" />
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-flash-outline showcase-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="gameplay" className="show-section">
        <div className="container">
          <div className="eight columns">
            <div className="row">
              <h2>Learn First, Play Later</h2>
            </div>
            <div className="row">
              <div className="six columns">
                <p>
                  Are you new to learning German and feeling overwhelmed? Don’t
                  worry! Our learning mode allows you to master the articles and
                  words at your own pace. And if you come across an unfamiliar
                  word, simply call up its definition with ease.
                </p>
                <p></p>
              </div>
              <div className="six columns">
                <p></p>
                <p></p>
              </div>
            </div>
          </div>
          <div className="four columns">
            <StaticImage
              src="../images/mirror/en/6.png"
              className="showcase-img"
              alt="phone mockup"
              placeholder={"blurred"}
              fit={"contain"}
              height={750}
            />
          </div>
        </div>
      </section>

      <section id="gameplay" className="show-section">
        <div className="container">
          <div className="four columns">
            <StaticImage
              src="../images/mirror/en/5.png"
              className="showcase-img"
              alt="phone mockup"
              placeholder={"blurred"}
              fit={"contain"}
              height={750}
            />
          </div>
          <div className="eight columns">
            <div className="row">
              <h2>Reference</h2>
            </div>
            <div className="row">
              <div className="six columns">
                <p>
                  Stumped by an unfamiliar word in German class or during
                  self-study? Simply search for it and discover its definition,
                  translation, example sentences, article and declension. Plus,
                  add it to your favorites for easy review later. Never be
                  caught off guard by a new word again!
                </p>
                <p></p>
              </div>
              <div className="six columns">
                <p></p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="wordlist" className="show-section">
        <div className="container">
          <div className="row" id="section-heading">
            <h4 style={{ textAlign: "center" }}>
              Download the app now and start playing today. You will be
              surprised how quickly and easily you will learn the articles.
            </h4>
          </div>
        </div>
      </section>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginBottom: "3rem",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "0rem" }}
        >
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.der.die.das.train"
          >
            <StaticImage
              src="../images/google-play-badge.png"
              alt="Download on Google Play"
              style={{ color: "#f67a40" }}
              placeholder={"blurred"}
              fit={"contain"}
              height={60}
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://apps.apple.com/us/app/der-die-das-train-lite/id1562981120?platform=iphone"
          >
            <StaticImage
              src="../images/apple-store-badge.png"
              alt="Download on Apple Store"
              style={{ color: "#f67a40" }}
              placeholder={"blurred"}
              fit={"contain"}
              height={60}
            />
          </a>
        </div>
      </div>
      <footer className="showcase-footer">
        <section id="languages">
          <div className="container">
            <div className="row" id="section-heading">
              <h2>Available free?</h2>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <strong>Advertising</strong>
              <p style={{ color: "gray", textAlign: "left" }}>
                
              Most of our content is free and open to all users, however this learning tool is supported
                by advertising and ads will appear within the app. 
                To unlock premium features simply watch a short ad and enjoy access to exclusive features and no further ads
                for an entire week.  We’ve carefully curated our ads to ensure minimal
                disruption to your learning experience.
                
              </p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <strong></strong>
              <p style={{ color: "gray", textAlign: "left" }}>
              </p>
            </div>
            <section>
              <Link to={"/privacy-policy"}>Privacy Policy</Link> |{" "}
              <Link to={"/terms-and-conditions/"}>Terms and Conditions</Link>
              <span>
                {` `}© {new Date().getFullYear()}
                {` `}
              </span>
              <span style={{ fontWeight: "bold" }} href="https://mapley.dev">
              Der Die Das Train
              </span>
            </section>
          </div>
        </section>
      </footer>
    </div>
  )
}
export default CardIndex
