import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const RewardComponent = ({rewardToken,prefix}) => {
  function startiThrown() {
    window.location.href = `der-die-das-train://referral/${rewardToken}`;
    var time = (new Date()).getTime();
    setTimeout(function(){
        var now = (new Date()).getTime();
        if((now - time)<400) {
          alert(!prefix ? "Please install the App first and then click for the reward!":"Bitte installiere zuerst die App und klicke dann für die Belohnung!")
         }
    }, 300);
}
  return (
  <div style={{display:'flex',flexDirection:'column', justifyContent:'center', width:'100%'}}>

  <div onClick={()=>{if(window){startiThrown()}}} style={{cursor:'pointer', display:'flex', flexDirection:'row',width:'100%',alignItems:'center'}}>
  <h3 className="subheader">{!prefix ?'Claim Referral Reward':'Empfehlungsprämie erhalten'}</h3>
  <div style={{padding:20}}>
  <StaticImage
                    src="../images/present.png"
                    alt="Reward Icon"
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={50}
                    width={50}
                  />
    </div>
  </div>
  <p className="paragraph">{!prefix ?'Install the App then click above to collect the reward.':'Installieren Sie die App und klicken Sie dann oben, um die Belohnung zu erhalten.'}</p>

  </div>
  )
}

export default RewardComponent
